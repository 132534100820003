import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { EventType, QueryParam, System } from "../commons/Constants";
import Logger from "../commons/Logger";
import { useLanderConfig } from "../commons/ConfigContext";
import { VerizonContent } from "../verizon/VerizonContent";
import { AdSense } from "./utils/adSenseUtils";
import loadAdsense from "./utils/loadAdsense";
import { sendBannerLinkEvent } from "../commons/LanderEvent";

function AdSenseBlock({ landerConfig, page, queryConfig }) {
  let adsenseContentDivId = "ads";
  if (page === AdSense.page.RELATED_LINKS) {
    adsenseContentDivId = "relatedLinks";
  }

  let search;
  if (
    !landerConfig.lander.template.includes("PARK_WEB") &&
    queryConfig[QueryParam.SEARCH_BOX]
  ) {
    search = (
      <div id="searchbox">
        <FormattedMessage id="contentBlockedText" />
      </div>
    );
  }

  return (
    <>
      <div id={ adsenseContentDivId }>
        <FormattedMessage id="contentBlockedText" />
      </div>
      {search}
    </>
  );
}

AdSenseBlock.propTypes = {
  page: PropTypes.string.isRequired,
  queryConfig: PropTypes.object.isRequired,
  landerConfig: PropTypes.object.isRequired,
};

export function AdContent({ landerConfig, queryConfig, page }) {
  const { isAdsenseSuccess, setIsAdsenseSuccess } = useLanderConfig();

  useEffect(() => {
    loadAdsense(landerConfig, queryConfig, page, setIsAdsenseSuccess);
  }, [landerConfig, page, queryConfig, setIsAdsenseSuccess]);

  const blockAdsense = landerConfig.isForcedVerizon || !isAdsenseSuccess;

  if (blockAdsense) {
    if (landerConfig.system === System.PARKWEB) {
      try {
        sendBannerLinkEvent(landerConfig, EventType.SERP_REDIRECT);
        window.location.assign(landerConfig.lander?.banner?.link);
      } catch (e) {
        Logger.error(
          "Missing valid redirect to SERP from" +
            landerConfig.domain.rootDomain +
            toString(e)
        );
      }
    } else {
      return <VerizonContent />;
    }
  } else if (!blockAdsense)
    return (
      <AdSenseBlock
        landerConfig={ landerConfig }
        queryConfig={ queryConfig }
        page={ page }
      />
    );

  return <FormattedMessage id="contentBlockedText" />;
}

AdContent.propTypes = {
  page: PropTypes.string.isRequired,
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired,
};
