import CssRule, { cssRule, cssRules } from "../../commons/CssRule";
import React from "react";
import { GoogleCaf } from "../utils/adSenseUtils";
import { getParkWebRelatedLinks, commonRulesArr } from "./ParkWeb";

export function getHegPageLevel() {
  return {
    uiOptimize: true,
    styleId: GoogleCaf.styleId.PARK_WEB_HEG,
  };
}

export function getHegRelatedLinks() {
  return getParkWebRelatedLinks();
}

export function commonRulesHeg() {
  return cssRules([
    ...commonRulesArr,
    new CssRule("#domainBanner", ["background-color: #0269a0;"]),
    new CssRule("#contentMain", ["height:100vh;"]),
    new CssRule("#contentLayout", [
      "color: #111111;",
      "font-family: GD Sherpa,arial;",
      "min-height: 100%",
      "display: grid;",
      "grid-template-rows: auto 1fr auto;",
    ]),
    new CssRule("#getButtonBox", [
      "background-color: #8dc63f;",
      "color: white;",
      "font-size: 16px;",
      "width: 248px;",
      "margin: auto;",
      "text-align: center;",
      "border-radius: 4px;",
    ]),
    new CssRule("#headerLogoWrapper", [
      "display: flex;",
      "align-items: center;",
      "color: #ffffff;",
      "padding: 36px 0px 0px 118px;",
      "width: 16vw;",
      "height: 12px;",
    ]),
    new CssRule("#headerLogoNumericSection", ["display: flex;"]),
    new CssRule("#headerLogoSectionOne", [
      "background-color: #e1144d;",
      "border-radius: 4px;",
      "display: flex;",
      "width: 35px;",
      "height: 35px;",
      "align-items: center;",
      "justify-content: center;",
      "margin: 0 2px 0 0;",
      "font-weight: bold;",
      "font-size: 24px;",
    ]),
    new CssRule("#headerLogoSectionTwo", [
      "background-color: #0093d1;",
      "border-radius: 4px;",
      "display: flex;",
      "width: 35px;",
      "height: 35px;",
      "align-items: center;",
      "justify-content: center;",
      "margin: 0 2px 0 0;",
      "font-weight: bold;",
      "font-size: 24px;",
    ]),
    new CssRule("#headerLogoSectionThree", [
      "background-color: #7eb828;",
      "border-radius: 4px;",
      "display: flex;",
      "width: 35px;",
      "height: 35px;",
      "align-items: center;",
      "justify-content: center;",
      "font-weight: bold;",
      "font-size: 24px;",
    ]),
    new CssRule("#headerLogoSectionLabel", [
      "display: flex;",
      "padding: 7px 14px;",
      "font-weight: bold;",
      "font-size: 32px;",
    ]),
    new CssRule("#headerText", [
      "display: flex;",
      "flex-direction: column;",
      "align-items: center;",
      "color: #ffffff;",
    ]),
    new CssRule("#headerTextTitle", ["font-size: 36px;"]),
    new CssRule("#headerTextContent", ["font-size: 20px;", "font-weigh: 300"]),
    new CssRule("#disclaimer", ["text-align: center;", "margin-bottom: 50px;"]),
    new CssRule("#headerFooter", [
      "color: #ffffff;",
      "padding: 36px 136px;",
      "text-align: center",
    ]),
  ]);
}

export function Heg() {
  return (
    <style>
      {commonRulesHeg()}
      {cssRule("#relatedLinks, #ads", [
        "width: 788px;",
        "min-width: 0px;",
        "max-width: 1230px;",
        "border: solid #D6D6D6 1px;",
        "padding: 16px 32px 32px;",
      ])}
    </style>
  );
}

export function HegMobile() {
  return (
    <style>
      {commonRulesHeg()}
      {cssRule("#gdLogo", [
        "padding-left: 0;",
        "padding-top: 17px;",
        "text-align: center;",
      ])}
      {cssRule("#domainBanner", ["padding-bottom: 10px;"])}
      {cssRule("#headerLogoWrapper", [
        "padding: 0 0 0 8px;",
        "width: 32vw;",
        "height: 48px;",
      ])}
      {cssRule("#headerTextContent", ["font-size: 16px;", "padding: 4px 8px;"])}
      {cssRule("#headerFooter", ["padding: 8px 24px;"])}
      {cssRule("#domain", [
        "font-size: 24px;",
        "font-weight: 500;",
        "line-height: 28px;",
        "margin-top: 12px;",
      ])}
      {cssRule("#domainInfo", [
        "padding-left: 5px;",
        "padding-right: 5px;",
        "margin-top: 7px;",
      ])}
      {cssRule("#getButton", ["padding-bottom: 10px;"])}
      {cssRule("#getButtonBoxLink", ["font-weight: normal;"])}
      {cssRule("#relatedLinks, #ads", [
        "width: 360px;",
        "border: solid #D6D6D6 1px;",
        "padding: 0;",
      ])}
    </style>
  );
}
