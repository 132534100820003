import React from "react";
import PropTypes from "prop-types";
import reactHtmlParser from "html-react-parser";
import Logger from "../../commons/Logger";
import { CopyrightCashParking } from "./CopyrightCashParking";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { TrustArcCookiePreferences } from "../../consentManager/TrustArcComponents";
import classes from "./footer.module.scss";

export const FooterCashParking = ({ landerConfig, queryConfig }) => {
  let footerElement;
  if (landerConfig.lander.footerHtml) {
    let decodedHtml = "";
    try {
      decodedHtml = window.atob(landerConfig.lander.footerHtml);
    } catch (e) {
      Logger.error("footerHtml value is not properly encoded" + e);
    }
    footerElement = (
      <div className="footerLine footerColor">
        <div id="footerElement">{reactHtmlParser(decodedHtml)}</div>
      </div>
    );
  } else if (landerConfig.lander.footerText) {
    footerElement = (
      <div className="footerLine footerColor">
        <div id="footerElement">{landerConfig.lander.footerText}</div>
      </div>
    );
  }

  return (
    <div id="footer">
      {footerElement}
      <div className={ `footerLine footerColor ${classes.spaceBetween}` }>
        <CopyrightCashParking
          landerConfig={ landerConfig }
          queryConfig={ queryConfig }
        />
        <PrivacyPolicy landerConfig={ landerConfig } />
        <TrustArcCookiePreferences />
      </div>
    </div>
  );
};

FooterCashParking.propTypes = {
  landerConfig: PropTypes.object,
  queryConfig: PropTypes.object,
};
