import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classes from "./footer.module.scss";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { TrustArcCookiePreferences } from "../../consentManager/TrustArcComponents";

export const FooterParkWeb = ({
  copyrightTextId,
  landerConfig,
  parkwebDisclaimerText = null,
}) => {
  return (
    <div className={ classes.parkwebFooter }>
      <div className={ classes.spaceBetween }>
        <span className={ classes.centerText }>
          <FormattedMessage
            id={ copyrightTextId }
            values={{ currentYear: new Date().getFullYear() }}
          />
        </span>
        <PrivacyPolicy landerConfig={ landerConfig } />
        <TrustArcCookiePreferences />
      </div>
      {parkwebDisclaimerText && (
        <p className={ classes.parkwebDisclaimerText }>
          <FormattedMessage id={ parkwebDisclaimerText } />
        </p>
      )}
    </div>
  );
};

FooterParkWeb.propTypes = {
  copyrightTextId: PropTypes.string,
  landerConfig: PropTypes.object,
  parkwebDisclaimerText: PropTypes.string,
};
