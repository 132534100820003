import { useEffect } from "react";
import { useLanderConfig } from "../commons/ConfigContext";
import { Settings } from "../config";
import { injectTrustArcScript } from "./helper";
import { TA_PREFERENCES_ID } from "./TrustArcComponents";

// Initializes TrustArc CMP
export const useTrustArcScript = () => {
  const { landerConfig } = useLanderConfig();

  useEffect(() => {
    const privacyUrl =
      landerConfig?.turnKeyReseller?.privacyUrl ?? Settings.PRIVACY_POLICY_URL;
    injectTrustArcScript(TA_PREFERENCES_ID, privacyUrl);
  }, [landerConfig.turnKeyReseller]);

  return;
};
