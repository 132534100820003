import React, { useState, createContext, useContext } from "react";
import PropTypes from "prop-types";
import { BannerType } from "../banner/Banner";
import { mergeTerms } from "./configMergeUtils";
import { getIsConsentRequired } from "../consentManager/helper";

export const ConfigContext = createContext({
  landerConfig: {},
  queryConfig: {},
  mergedConfig: {},
  isAdsenseSuccess: null,
  setIsAdsenseSuccess: () => {},
});

export const useLanderConfig = () => {
  return useContext(ConfigContext);
};

export const getTypeID = (type) => {
  if (type === BannerType.AUCTION) return "auctions";
  if (type === BannerType.DBS) return "broker";
  return "other";
};

export const getChannelID = (inTreatmentCohort, landerConfig) => {
  return inTreatmentCohort
    ? landerConfig.adSense.channel + "+treatment"
    : landerConfig.adSense.channel + "+control";
};

export const getExperimentBannerLink = (inTreatmentCohort, landerConfig) => {
  const type = landerConfig?.lander?.banner?.type ?? "";
  const url = new URL(landerConfig?.lander?.banner?.link ?? "");
  const cohortID = inTreatmentCohort ? "b" : "a";
  const campaignID = `x_dom-${getTypeID(
    type
  )}_parkedpages_x_x_invest_${cohortID}_001`;
  url.searchParams.set("utm_campaign", campaignID);
  return decodeURIComponent(url.href);
};

export const LanderConfigProvider = ({
  landerConfig,
  queryConfig,
  children = void 0,
}) => {
  const [isAdsenseSuccess, setIsAdsenseSuccess] = useState(true);
  if (
    landerConfig?.experiment?.key === "parking_parkweb_ab_03" &&
    getIsConsentRequired()
  ) {
    const inTreatmentCohort =
      landerConfig?.experiment?.bucket === "pw_treatment";
    landerConfig.lander.banner.link = getExperimentBannerLink(
      inTreatmentCohort,
      landerConfig
    );
    landerConfig.adSense.channel = getChannelID(
      inTreatmentCohort,
      landerConfig
    );
  }
  const mergedConfig = {
    keywords: mergeTerms(landerConfig, queryConfig),
  };

  const contextValue = {
    landerConfig: landerConfig,
    queryConfig: queryConfig,
    mergedConfig: mergedConfig,
    isAdsenseSuccess,
    setIsAdsenseSuccess,
  };

  return (
    <ConfigContext.Provider value={ contextValue }>
      {children}
    </ConfigContext.Provider>
  );
};
LanderConfigProvider.propTypes = {
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired,
  children: PropTypes.node,
};
